<template>
   <div id="hotelsGadget" class="min-h-48 lg:min-h-26 mb-1 p-2 w-full bg-secondary">
     <!-- Note: the div id value has to be the final part of the src URL path in order for the Kayak script to work -->
        <div id="KayakHotelsWidget"
                data-affiliate-id="kan_43186_85672"
                data-country-code="gb"
                data-auto-height="true"
                data-vertical="hotels"
                data-vertical-list="hotels"
                data-language-code="en"
                data-currency-code="GBP"
                data-theme="dark"
                :data-label="getWebSession"
                :data-url-prefix="dataUrlPrefix" 
                :src="kayakScriptLocation"/>
    </div>
</template> 

<script>
const PAGE_LANG = 'en-gb'

export default {
    name: 'HotelsGadget',
    components: {},
    data() {
      return {
        // Ensure that the final URL path is the same as the div id that references the script
        kayakScriptLocation: 'https://hotels.travelsupermarket.com/search-widget/script/uwl/KayakHotelsWidget',
        webSessionId: '',
        dataLocationId: 'tsm_default',
        dataUrlPrefix: 'https://hotels.travelsupermarket.com'
      };
    },
    props: {
        data: Object,
    },
    computed: {
      getWebSession() {
          return this.webSessionId
      }
    },
    mounted() {
      let recaptchaScript = document.createElement('script')
      recaptchaScript.setAttribute('src', this.kayakScriptLocation)
      document.head.appendChild(recaptchaScript)
      if (location.pathname.includes(PAGE_LANG)) {
        let pathnameParts = location.pathname.split(PAGE_LANG)
        this.dataLocationId = pathnameParts[1].replaceAll('/', '_').replaceAll('-', '_')
      }
    }
}
</script>